<template>
    <div style="width: 100%; height: 100%; padding: 0px !important;">

        <svg 
            height="100%" 
            width="100%" 
            style="background-color: transparent;" 
            :style="{ cursor: prop.timelineCursor }" 
            @click="$event => $emit('timelineBarClicked', $event, bars[0])" 
            @mousedown="$event => handleMouseDown($event, bars[0])"
            @mouseup="$event => handleMouseUp($event)"
            @mousemove="handleMouseMoveSVG($event, bars[0])">

            <polygon 
                v-for="bar in bars" 
                :key="bar.name" 
                :points="getPointsByBar(bar)" 
                style="stroke-linejoin: round; z-index: 100;" 
                :style="{ cursor: barCursor }" 
                :stroke="getOutlineColor(bar)" 
                :stroke-width="getStrokeWidth(bar)" 
                :fill="getFillColor(bar)"
                @mousedown="$event => handleMouseDown($event, bar)"
                @mouseup="$event => handleMouseUp($event)"
                @mousemove="handleMouseMove($event)"
                @mouseover="showTooltip($event, bar)" 
                @mouseleave="hideTooltip">
            </polygon>
        </svg>

        <span 
            v-for="label in labels" 
            :key="label.name" 
            class="ms-3 user-select-none" 
            style="position: absolute; width: 22px; padding: 0; top: 5px; bottom: 5px; background-color: transparent;" 
            :style="{ left: getBarEndPosition(bars[0]) + 'px' }">
            {{ label.name }}
        </span>

        <div 
            v-for="icon in icons" 
            :key="icon.name" 
            class="ms-3" 
            :class="icon.type.icon.color"
            style="position: absolute; width: 30px; padding: 0; top: 8px; bottom: 10px; background-color: transparent;" 
            :style="{ left: getBarEndPosition(bars[0]) + 'px' }">
            <i class="bi" :class="icon.type.icon.class" data-bs-toggle="tooltip" :title="icon.name"></i>
        </div>
        
        <div v-if="bars[0]?.type.active">
            <button 
                @click="$event => $emit('leftArrowClick', $event)" 
                v-if="new Date(bars[0]?.to) < prop.timelineLeftDate" 
                style="position: absolute; width: 22px; padding: 0; top: 5px; bottom: 5px; left: 2px; background-color: transparent;">
                <svg 
                    height="15px" 
                    width="100%" 
                    style="background-color: transparent; cursor: pointer">
                    <polygon 
                        points="0 5 15 0 13 3 20 3 20 7 13 7 15 10 0 5" 
                        style="stroke-linejoin: round; z-index: 100; stroke-width: 0.75;" 
                        :stroke="getOutlineColor(bars[0])" 
                        :fill="getFillColor(bars[0])" />
                </svg>
            </button>

            <button 
                @click="$event => $emit('rightArrowClick', $event)" 
                v-if="new Date(bars[0]?.from) > prop.timelineRightDate" 
                style="position: absolute; width: 22px; padding: 0; top: 5px; bottom: 5px; right: 2px; background-color: transparent;">
                <svg 
                    height="15px" 
                    width="100%" 
                    style="background-color: transparent; cursor: pointer">
                    <polygon 
                        points="20 5 5 0 7 3 0 3 0 7 7 7 5 10 20 5" 
                        style="stroke-linejoin: round; z-index: 100; stroke-width: 0.75;" 
                        :stroke="getOutlineColor(bars[0])" 
                        :fill="getFillColor(bars[0])" />
                </svg>
            </button>
        </div>

    </div>
</template>


<script setup lang="ts">
import { defineProps, defineEmits, ref, computed } from 'vue';

const prop = defineProps({
    bars: Object,
    barTypes: Object,
    timelineWidth: Number,
    timelineLeftDate: Date,
    timelineRightDate: Date,
    timelineCursor: String,
    disableResize: Boolean
});

const currentBar = ref(null);
const barCursor = ref( prop.disableResize ? prop.timelineCursor : 'grab');
const tooltipInstance = ref(null);

let initialBarTo = 0;
let initialMouseX = 0;
let initialBarFrom = 0;
let isDraggingBar = ref(false);
let isHoveringLeftEdge = false;
let isDraggingSide = ref(false);
let isHoveringRightEdge = false;

const emit = defineEmits(['panTimeline', 'stopPanning', 'cursor-changed', 'dates-changed', 'isDragging']);

const labels = computed(() => {
    return prop.bars.filter(bar => {
        return bar.type.active && bar.type.shape === 'text';
    });
});

const icons = computed(() => {
    return prop.bars.filter(bar => {
        return bar.type.active && bar.type.icon && bar.name;
    });
});

function handleMouseDown(event, bar) {

    const target = event.target;

    if ((target.tagName == 'svg' && !isHoveringLeftEdge && !isHoveringRightEdge) || prop.disableResize) {
        emit('panTimeline', event)
    }
    else if (isHoveringLeftEdge || isHoveringRightEdge) {
        isDraggingSide.value = true
        emit('isDragging', isDraggingSide.value)
    } 
    else {
        barCursor.value = 'grabbing';
        isDraggingBar.value = true;
        initialMouseX = event.offsetX;

        emit('isDragging', isDraggingBar.value)

        initialBarFrom = getTimelinePositionByDate(new Date(bar.from)) // bar left
        const initialBarToTemp = getTimelinePositionByDate(new Date(bar.to)) // bar right temp
        initialBarTo = (initialBarToTemp > initialBarFrom + 3 ? initialBarToTemp : initialBarFrom + 3); // bar right

        currentBar.value = bar;
    }
    
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

}

function adjustLeftRight(event, direction) {

    const bar = currentBar.value;
    const deltaX = event.offsetX - initialMouseX;
    let newDate;
    
    if (direction === 'left') {
        const newStartX = initialBarFrom + deltaX;
        newDate = getDateByTimelinePosition(newStartX);
        bar.from = newDate;
    } 
    else if (direction === 'right') {
        const newEndX = initialBarTo + deltaX;
        newDate = getDateByTimelinePosition(newEndX);
        bar.to = newDate;
    }

    emit('dates-changed', bar);
    
}

function handleMouseMoveSVG(event, bar) {

    currentBar.value = bar;
    if (!isDraggingBar.value && !isDraggingSide.value) {

        initialBarFrom = getTimelinePositionByDate(new Date(bar.from));
        const initialBarToTemp = getTimelinePositionByDate(new Date(bar.to)) // bar right temp
        initialBarTo = (initialBarToTemp > initialBarFrom + 3 ? initialBarToTemp : initialBarFrom + 3);

        initialMouseX = event.offsetX;
        
        const threshold = 5;

        const hoverRangeStartLeft = initialBarFrom - threshold;
        const hoverRangeEndLeft = initialBarFrom + threshold;

        const hoverRangeStartRight = initialBarTo - threshold;
        const hoverRangeEndRight = initialBarTo + threshold;

        isHoveringLeftEdge =  initialMouseX >= hoverRangeStartLeft &&  initialMouseX <= hoverRangeEndLeft;
        isHoveringRightEdge =  initialMouseX >= hoverRangeStartRight &&  initialMouseX <= hoverRangeEndRight;

        const isWithinPolygon = initialMouseX > hoverRangeEndLeft && initialMouseX < hoverRangeStartRight

        if ((isHoveringLeftEdge || isHoveringRightEdge) && !prop.disableResize) {
            barCursor.value = 'e-resize';
            emit('cursor-changed', barCursor.value) 
        }
        else if (isWithinPolygon && !prop.disableResize) {
            barCursor.value = 'grab';
            emit('cursor-changed', barCursor.value)
        }
        else {
            barCursor.value = 'all-scroll';
            emit('cursor-changed', barCursor.value)
        }
    } 

    if (isDraggingSide.value && !prop.disableResize ) {
        isHoveringLeftEdge ? adjustLeftRight(event, 'left') : adjustLeftRight(event, 'right')
    }

}

function handleMouseMove(event) {

    const bar = currentBar.value

    if (isDraggingBar.value && !prop.disableResize) {
        
        
        const deltaX = event.offsetX - initialMouseX;
        const newStartX = initialBarFrom + deltaX;
        const newEndX = initialBarTo + deltaX;
    
        const newStartDate = getDateByTimelinePosition(newStartX);
        const newEndDate = getDateByTimelinePosition(newEndX);

        bar.from = newStartDate
        bar.to = newEndDate

        emit('dates-changed', bar);
        tooltipInstance.value.hide();
    }

    if (isDraggingSide.value && !prop.disableResize) {
        isHoveringLeftEdge ? adjustLeftRight(event, 'left') : adjustLeftRight(event, 'right')
        tooltipInstance.value.hide();
    }

}

function handleMouseUp(event) {
    const bar = currentBar.value

    isDraggingBar.value = false;
    isDraggingSide.value = false;
    emit('isDragging', isDraggingBar.value)
    emit('stopPanning', event)
    barCursor.value = 'grab';

    if (!prop.disableResize) {
        emit('dates-changed', bar);
    }
    
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
}

const showTooltip = (event, bar) => {
    const tooltipElement = event.target;
    tooltipInstance.value = new bootstrap.Tooltip(tooltipElement, {
        title: getBarName(bar),
        placement: 'top',
        trigger: 'manual',
    });
    tooltipInstance.value.show();
    
};

const hideTooltip = () => {
    if (tooltipInstance.value && tooltipInstance.value._element) {
        tooltipInstance.value.hide();
    }
};

function getDateByTimelinePosition(x) {
    const positionRatio = x / prop.timelineWidth;
    const timeRange = prop.timelineRightDate.getTime() - prop.timelineLeftDate.getTime();
    const timeOffset = timeRange * positionRatio;
    const newDate = new Date(prop.timelineLeftDate.getTime() + timeOffset);
    return newDate;
}

function getBarName(bar) {
    if (bar.name) {
        return bar.name
    }
    return bar.type.name
}

function getBarEndPosition(bar) {
    const barLeftPosition = getTimelinePositionByDate(new Date(bar.from))
    const barRightPositionTemp = getTimelinePositionByDate(new Date(bar.to));
    const barRightPosition = (barRightPositionTemp > barLeftPosition + 3 ? barRightPositionTemp : barLeftPosition + 3);

    return barRightPosition;
}

function areLabelsActive() {
    const bars = prop.barTypes
    return bars?.name == 'Labels' && bars.active ? true : false
};

function getDate(bar) {
    const start = new Date(bar.from).toLocaleString();
    const finish = new Date(bar.to).toLocaleString();
    
    if (start === finish) {
        return '\n\nDate: ' + start
    }
    else {
        return '\n\nFrom: ' + start + '\nTo: ' + finish
    }
}

function getOutlineColor(bar) {
    if (bar.border) {
        return bar.type?.border?.color;
    }
    return;
}

function getStrokeWidth(bar) {
    if (bar.type?.border?.thickness) {
        return bar.type.border.thickness;
    }
    return;
}

function getTimelinePositionByDate (date) {
    if (!date || !prop.timelineLeftDate || !prop.timelineRightDate || !prop.timelineWidth) {
        return 0;
    }
    
    return prop.timelineWidth * (date.getTime() - prop.timelineLeftDate.getTime()) / (prop.timelineRightDate.getTime() - prop.timelineLeftDate.getTime());
};

function getBarPoints(x, x2, top, height) {
    const width = x2 - x;
    const bottom = top + height;

    return x + " " + top + " " + x2 + " " + top + " " + x2 + " " + bottom + " " + x + " " + bottom + " " + x + " " + top + " ";    
}

function getProgressPoints(x, x2, top, height){
    const width = x2 - x;
    const bottom = top + height;
    x = x + 1;
    return x  + " " + top + " " + x2 + " " + top + " " + x2 + " " + bottom + " " + x + " " + bottom + " " + x + " " + top + " ";
}

function getSummaryPoints (x, x2) {
    const angleWidth = 7;
    const innerHeightFactor = 0.6;
    const top = 5;
    const bottom = 20;
    const width = x2 - x;
    const height = bottom - top;

    return x + " " + top + " " + x2 + " " + top + " " + x2 + " " + bottom + " "
            + (width * 0.5 > angleWidth ? x2 - angleWidth : x2 - width/2) + " "
            + (top + height * innerHeightFactor) + " "
            + (width * 0.5 > angleWidth ? x + angleWidth : x + width/2) + " "
            + (top + height * innerHeightFactor) + " "
            + x + " " + bottom + " " + x + " " + top + " ";
}


function getProgressSummaryPoints(x, x2){
    const top = 8;
    const bottom = 11;
    const width = x2 - x;
    const height = bottom - top;
    x++;
    return x + " " + top + " " + x2 + " " + top + " " + x2 + " " + bottom + " " 
            + x + " " + bottom + " " + x + " " + top + " ";

}

function getPointShapePoints(x, top = 2, height = 8) {
    const width = height;
    return (x - width/2) + " " + (top + height/2) + " " + x + " " + top + " " + (x + width/2) + " " + (top + height/2) + " " + x + " " + (top + height) + " " + (x - width/2) + " " + (top + height/2);
}

function getPointsByBar(bar) {
    const barLeftPosition = getTimelinePositionByDate(new Date(bar.from))
    const barRightPositionTemp = getTimelinePositionByDate(new Date(bar.to));
    const barRightPosition = (barRightPositionTemp > barLeftPosition + 3 ? barRightPositionTemp : barLeftPosition + 3);
    
    if (!bar.type?.active) {
        return;
    }

    if (barRightPosition <= 0) {
        return;
    } else if (barLeftPosition >= prop.timelineWidth) {
        return;
    }

    if (bar.summary == true && !bar.progress){
        return getSummaryPoints(barLeftPosition, barRightPosition);
    }

    if (bar.from && bar.to && bar.from == bar.to && bar.type?.shape === 'diamond' || bar.pointShape){
        return getPointShapePoints(barLeftPosition, bar.top != undefined ? bar.top : bar.type?.top, bar.height != undefined ? bar.height : bar.type?.height);
    }

    if (!bar.from || !bar.to) {
        return;
    }
    
    return getBarPoints(barLeftPosition, barRightPosition, bar.top != undefined ? bar.top : bar.type?.top, bar.height != undefined ? bar.height : bar.type?.height);
}

function getFillColor(bar) {
    if (bar.color){
        return bar.color;
    }
    if (bar.type?.color) {
        return bar.type.color;
    }
    if (bar.summary) {
        return bar.type.summary.color;
    }
    if (bar.pointShape && bar.type?.pointShape?.color) {
        return bar.type.pointShape.color;
    }
    if (bar.pointShape && !bar.type?.pointShape?.color) {
        return bar.type.color;
    }

    if (bar.from == bar.to) {
        return bar.type?.pointShape?.color;
    }

    return '#8ee997';
}

</script>

<style>
  .tooltip .tooltip-inner {
    background-color: white;
    color: black;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
    border-top-color: white
  }

</style>